import { CSSProperties } from 'react';

export const carouselSceneStyle: CSSProperties = {
  position: 'relative'
};

export const carouselStyle: CSSProperties = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  transformStyle: 'preserve-3d',
  transition: 'transform 1s'
};

export const carouselItemStyle: CSSProperties = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  transition: 'transform 1s',
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  flexDirection: 'column',
  cursor: 'pointer'
};
