import { Asset } from '../types';
import { getLargestImage } from './getLargestImage';

const imageCMSRegex = /https:\/\/cms-fym\.s3\.nl-ams.scw\.cloud\/(.+)/gm;
// https://cms-fym.s3.nl-ams.scw.cloud/large_download_audio_from_youtube_08ac407772.png
export const getIMGIXOptimisedImage = (
  asset: Asset | undefined | null,
  maxSize: 'large' | 'medium' | 'small' | 'thumbnail' | 'original'
) => {
  let imgixMaxSize = 1200;
  switch (maxSize) {
    default:
    case 'original':
      imgixMaxSize = 1600;
      break;
    case 'large':
      imgixMaxSize = 1200;
      break;
    case 'medium':
      imgixMaxSize = 1000;
      break;
    case 'small':
      imgixMaxSize = 800;
      break;
    case 'thumbnail':
      imgixMaxSize = 600;
      break;
  }
  const url = getLargestImage(asset, 'original');
  if (!url) {
    return undefined;
  }
  return url.replace(
    imageCMSRegex,
    `https://cms-fym.imgix.net/$1?auto=compress,format&fit=fillmax&ch=Save-Data&w=${imgixMaxSize}&max-h=${imgixMaxSize}`
  );
};
