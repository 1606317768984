import React, { useCallback } from 'react';
import classNames from 'classnames';
import { CarouselItemProps, CarouselProps } from './CarouselTypes';
import { carouselItemStyle, carouselSceneStyle, carouselStyle } from './CarouselStyles';

// https://3dtransforms.desandro.com/carousel

export const CarouselItem: React.FC<React.PropsWithChildren<CarouselItemProps>> = ({
  children,
  width,
  height,
  angle,
  rotateFn,
  radius,
  className,
  isActive,
  indexForSelection,
  onSelect
}) => {
  const onClick = useCallback(() => {
    if (onSelect) {
      onSelect(indexForSelection);
    }
  }, [indexForSelection, onSelect]);
  return (
    <div
      style={{ ...carouselItemStyle, width, height, transform: `${rotateFn}(${angle}deg) translateZ(${radius}px)` }}
      className={classNames(className, { isActive })}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      aria-hidden
    >
      {children}
    </div>
  );
};
export const Carousel: React.FC<CarouselProps> = React.memo(
  ({
    children,
    width,
    height,
    isHorizontal,
    selectedItem,
    style,
    className,
    perspectiveSize,
    itemClassName,
    isReversed,
    onItemSelect
  }) => {
    const itemCount = children.length;

    const selectedIndex = selectedItem;
    const realSelectedIndex = Math.abs(selectedItem % itemCount);
    const totalRotationsAlreadyMade = Math.floor(Math.abs(selectedIndex / itemCount));
    const selectedIndexAdjustment = selectedItem < 0 ? -1 : 1; // if it's negative we need to turn output to neg

    const angle = (360 / itemCount) * selectedIndex * -1 * (isReversed ? -1 : 1);

    const cellSize = isHorizontal ? width : height;
    const rotateFn = isHorizontal ? 'rotateY' : 'rotateX';
    const radius = Math.round(cellSize / 2 / Math.tan(Math.PI / itemCount));

    const perspective = perspectiveSize ?? 120;

    const containerRealSize = Math.round(cellSize / 2 / Math.tan(Math.PI / itemCount));
    return (
      <div
        style={{
          width: isHorizontal ? containerRealSize : width,
          height: isHorizontal ? height : containerRealSize,
          paddingTop: isHorizontal ? 0 : (containerRealSize - height) / 2,
          paddingLeft: isHorizontal ? (containerRealSize - width) / 2 : 0
        }}
      >
        <div style={{ ...carouselSceneStyle, width, height, perspective, ...style }} className={className}>
          <div style={{ ...carouselStyle, transform: `translateZ(${-radius}px) ${rotateFn}(${angle}deg)` }}>
            {children.map((item, index) => {
              let realIndex = index;
              if (selectedIndexAdjustment < 0 && realIndex > 0) {
                realIndex = itemCount - realIndex;
              }

              let indexForSelection = totalRotationsAlreadyMade * itemCount + realIndex;
              // if we in 3rd quarter of a circle, then first quarter needs to point to one rotation higher
              if (realSelectedIndex > (itemCount * 3) / 4 && realIndex < itemCount / 4) {
                indexForSelection += itemCount;
              }

              // if we are in first quarter of circle, then 4th quarter needs to point to one rotation lower
              if (realSelectedIndex <= itemCount / 4 && realIndex >= (itemCount * 3) / 4) {
                indexForSelection -= itemCount;
              }

              indexForSelection *= selectedIndexAdjustment;

              return (
                <CarouselItem
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  indexForSelection={indexForSelection}
                  width={width}
                  height={height}
                  angle={(360 / itemCount) * index * (isReversed ? -1 : 1)}
                  radius={radius}
                  rotateFn={rotateFn}
                  className={itemClassName}
                  isActive={realSelectedIndex === realIndex}
                  onSelect={onItemSelect}
                >
                  {item}
                </CarouselItem>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
);
