import { useCallback, useState } from 'react';

export const useHovered = (): {
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  isHovered: boolean;
} => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const onMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  return {
    onMouseEnter,
    onMouseLeave,
    isHovered
  };
};
