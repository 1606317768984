import { Asset } from '../types';

export const getLargestImage = (
  asset: Asset | undefined | null,
  maxSize: 'large' | 'medium' | 'small' | 'thumbnail' | 'original'
) => {
  if (!asset) {
    return undefined;
  }
  if (maxSize === 'original' && asset.attributes.url) {
    return asset.attributes.url;
  }
  if (maxSize === 'large' && asset.attributes.formats?.large?.url) {
    return asset.attributes.formats.large.url;
  }
  if (maxSize === 'medium' && asset.attributes.formats?.medium?.url) {
    return asset.attributes.formats.medium.url;
  }
  if (maxSize === 'small' && asset.attributes.formats?.small?.url) {
    return asset.attributes.formats.small.url;
  }
  if (maxSize === 'thumbnail' && asset.attributes.formats?.thumbnail?.url) {
    return asset.attributes.formats.thumbnail.url;
  }
  return asset.attributes.url;
};
