import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Container } from 'reactstrap';
import { DownloadButtons } from '../../../components/DownloadButtons';
import { TopComponent } from '../components/TopComponent';
import { HeaderTitle } from '../components/HeaderTitle';
import { MobileDesktopScreenshot } from '../components/MobileDesktopScreenshot';
import { spotify } from '../../../components/musicServices/services/Spotify';
import { appleMusic } from '../../../components/musicServices/services/AppleMusic';
import { tidal } from '../../../components/musicServices/services/Tidal';
import { soundcloud } from '../../../components/musicServices/services/SoundCloud';
import { deezer } from '../../../components/musicServices/services/Deezer';

const TopContainerComponent: React.FC<React.PropsWithChildren<any>> = React.memo(({ children, ...props }) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Container fluid {...props}>
      {children}
    </Container>
  );
});
export const IndexTop: React.FC = React.memo(() => {
  return (
    <TopComponent ContainerClass={TopContainerComponent}>
      <div className="d-flex flex-row justify-content-center my-5 pt-0 pt-xl-5">
        <div className="top-component-left">
          <HeaderTitle>
            <FormattedMessage id="web.home.top.title" defaultMessage="Transfer, sync & move your music library" />
          </HeaderTitle>
          <h2 className="lead">
            <FormattedMessage
              id="web.home.top.text-download"
              defaultMessage="Discover the easiest way to move your liked songs, favorite playlists and albums."
            />
            <br />
            <br />
            <FormattedMessage
              id="web.home.top.text-download2"
              defaultMessage="Transfer your music between streaming platforms. Including {musicServices} and more!"
              values={{
                musicServices: (
                  <>
                    {[spotify, appleMusic, tidal, soundcloud, deezer].map((ms, index) => {
                      return (
                        <>
                          {index !== 0 && ', '}
                          <span style={{ color: ms.textColor ?? ms.color }}>{ms.shortName}</span>
                        </>
                      );
                    })}
                  </>
                )
              }}
            />
          </h2>
          <DownloadButtons />
        </div>
        <MobileDesktopScreenshot className="top-component-right d-none d-xl-flex" />
      </div>
    </TopComponent>
  );
});
