import { GetStaticProps } from 'next';
import { Home } from '../src/containers/pages/IndexPage';
import { blogGetStaticProps } from '../src/containers/blog/posts/blogGetStaticProps';

import { BlogPostsResponse } from '../src/containers/blog/types'; // eslint-disable-next-line import/no-default-export

// eslint-disable-next-line import/no-default-export
export default Home;

export const getStaticProps: GetStaticProps<{
  blogPosts: BlogPostsResponse;
}> = blogGetStaticProps({
  limit: 6,
  onlyIntros: true
});
