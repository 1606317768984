import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import Link from 'next/link';

export const IndexHowItWorksBigCTA: React.FC<{
  className: string;
}> = ({ className }) => {
  return (
    <div className={classNames('py-5 py-md-3 premium-big-cta-container', className)}>
      <Link prefetch={false} href="/download/" className="premium-big-cta">
        <div className="premium-big-cta-price">
          <FormattedMessage id="web.howitworks.download" defaultMessage="Download now" />{' '}
        </div>
      </Link>
    </div>
  );
};
