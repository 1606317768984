import React, { Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from 'reactstrap';
import { useIntl } from 'react-intl';
import { Carousel } from '../Carousel/Carousel';
import { reversedSupportedMusicServices, supportedMusicServices } from './allMusicServices';
import { useHovered } from '../hooks/useHovered';
import { MusicService } from './services/types';
import { nextAsset } from '../nextAsset';
import { FymButton } from '../FymButton';

const ServiceItem: React.FC<{
  ms: MusicService;
}> = React.memo(({ ms }) => {
  return (
    <div className="available-music-services-carousel-item">
      <div className="available-music-services-carousel-item-bg bg-dark">
        <div className="music-logo h-100 w-100">
          <img src={ms.logoPadding.dark} alt={ms.name} />
        </div>
      </div>
    </div>
  );
});

const CarouselMusicService: React.FC<{
  isReversed?: boolean;
  selectedIndex: number;
  width: number;
  height: number;
  onItemSelect?: (index: number) => void;
}> = React.memo(({ isReversed, selectedIndex, width, height, onItemSelect }) => {
  const items = useMemo(() => (isReversed ? reversedSupportedMusicServices : supportedMusicServices), [isReversed]);
  const doubledItems = useMemo(() => [...items, ...items], [items]);
  return (
    <Carousel
      width={width}
      height={height}
      selectedItem={selectedIndex}
      itemClassName="available-music-services-carousel-item-container"
      isReversed={isReversed}
      onItemSelect={onItemSelect}
    >
      {doubledItems.map((ms) => (
        <ServiceItem key={ms.id} ms={ms} />
      ))}
    </Carousel>
  );
});

export interface MovingOptionsCarouselProps {
  sourceSelectedItem?: MusicService;
  targetSelectedItem?: MusicService;
  stopped?: boolean;
}

export const MovingOptionsCarousel: React.FC<MovingOptionsCarouselProps> = React.memo(
  ({ sourceSelectedItem, targetSelectedItem, stopped }) => {
    const intl = useIntl();
    const sourceSelectedIndex: number | undefined = useMemo(
      () => sourceSelectedItem && supportedMusicServices.findIndex((ms) => sourceSelectedItem === ms),
      [sourceSelectedItem]
    );
    const targetSelectedIndex: number | undefined = useMemo(
      () => targetSelectedItem && (supportedMusicServices.findIndex((ms) => targetSelectedItem === ms) + 1) * -1,
      [targetSelectedItem]
    );
    const [carouselFromSelected, setCarouselFromSelected] = useState<number>(sourceSelectedIndex ?? 0);
    const [carouselToSelected, setCarouselToSelected] = useState<number>(targetSelectedIndex ?? -6);
    const { onMouseEnter, onMouseLeave, isHovered } = useHovered();
    const timeoutHandle = useRef<number | undefined>(undefined);

    const intervalCallback = useCallback(() => {
      const isWindowFocused = document.hasFocus();
      if (isHovered || !isWindowFocused || stopped) {
        return;
      }
      setCarouselFromSelected(carouselFromSelected + 1);
      setCarouselToSelected(carouselToSelected + 1);
    }, [carouselFromSelected, carouselToSelected, isHovered, stopped]);

    const scheduleTimeout = useCallback(() => {
      if (timeoutHandle.current) {
        clearTimeout(timeoutHandle.current);
      }
      timeoutHandle.current = window.setTimeout(intervalCallback, 2000);
    }, [intervalCallback]);

    const moveCarouselUp = useCallback(() => {
      setCarouselFromSelected(carouselFromSelected + 1);
      setCarouselToSelected(carouselToSelected - 1);
    }, [carouselFromSelected, carouselToSelected]);

    const moveCarouselDown = useCallback(() => {
      setCarouselFromSelected(carouselFromSelected - 1);
      setCarouselToSelected(carouselToSelected + 1);
      scheduleTimeout();
    }, [carouselFromSelected, carouselToSelected, scheduleTimeout]);

    const selectFromCarouselItem = useCallback((index: number) => {
      setCarouselFromSelected(index);
    }, []);

    const selectToCarouselItem = useCallback((index: number) => {
      setCarouselToSelected(index);
    }, []);

    useEffect(() => {
      scheduleTimeout();

      const timeout = timeoutHandle.current;
      return () => {
        if (timeout) {
          clearTimeout(timeout);
        }
      };
    }, [scheduleTimeout, timeoutHandle]);

    const selectedFromMusicService = useMemo(() => {
      const realIndex =
        carouselFromSelected < 0 ? supportedMusicServices.length + carouselFromSelected : carouselFromSelected;
      const realSelectedIndex = Math.abs(realIndex % supportedMusicServices.length);
      return supportedMusicServices[realSelectedIndex];
    }, [carouselFromSelected]);
    const selectedToMusicService = useMemo(() => {
      const realIndex =
        carouselToSelected < 0 ? supportedMusicServices.length + carouselToSelected : carouselToSelected;
      const realSelectedIndex = Math.abs(realIndex % reversedSupportedMusicServices.length);
      return reversedSupportedMusicServices[realSelectedIndex];
    }, [carouselToSelected]);
    return (
      <Suspense fallback={null}>
        <div className="d-flex flex-column my-5">
          <div
            className="available-music-services-carousel d-flex flex-row align-items-center justify-content-center"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <div className="d-none d-md-block">
              <CarouselMusicService
                selectedIndex={carouselFromSelected}
                width={260}
                height={73}
                onItemSelect={selectFromCarouselItem}
              />
            </div>
            <div className="d-md-none">
              <CarouselMusicService
                selectedIndex={carouselFromSelected}
                width={140}
                height={64}
                onItemSelect={selectFromCarouselItem}
              />
            </div>
            <div className="px-1 px-md-5 available-music-services-divider">
              <div className="d-flex justify-content-between flex-column">
                <Button color="transparent" onClick={moveCarouselUp} className="d-flex justify-content-center">
                  <img src={nextAsset(`images/caret-up-dark.svg`)} alt="Up" />
                </Button>
                <div className="py-5">
                  <img
                    src={nextAsset('images/home/arrows-border-gradient.svg')}
                    aria-hidden="true"
                    alt=""
                    className="img-fluid d-none d-md-block"
                  />
                  <img
                    src={nextAsset('images/home/arrows-gradient.svg')}
                    aria-hidden="true"
                    alt=""
                    className="img-fluid d-md-none"
                  />
                </div>
                <Button color="transparent" onClick={moveCarouselDown} className="d-flex justify-content-center">
                  <img src={nextAsset(`images/caret-down-dark.svg`)} alt="Down" />
                </Button>
              </div>
            </div>
            <div className="d-none d-md-block">
              <CarouselMusicService
                selectedIndex={carouselToSelected}
                isReversed
                width={260}
                height={73}
                onItemSelect={selectToCarouselItem}
              />
            </div>
            <div className="d-md-none">
              <CarouselMusicService
                selectedIndex={carouselToSelected}
                isReversed
                width={140}
                height={64}
                onItemSelect={selectToCarouselItem}
              />
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-center mb-5">
            <FymButton outline href={`/transfer-${selectedFromMusicService?.id}-to-${selectedToMusicService?.id}`}>
              <div>
                {intl.formatMessage(
                  {
                    id: 'web.carousel.transfer_x_to_y',
                    defaultMessage: 'Transfer {fromMusicService} to {toMusicService}'
                  },
                  {
                    fromMusicService: (
                      <span style={{ color: selectedFromMusicService?.textColor ?? selectedFromMusicService?.color }}>
                        {selectedFromMusicService?.shortName}
                      </span>
                    ),
                    toMusicService: (
                      <span style={{ color: selectedToMusicService?.textColor ?? selectedToMusicService?.color }}>
                        {selectedToMusicService?.shortName}
                      </span>
                    )
                  }
                )}
              </div>
            </FymButton>
          </div>
        </div>
      </Suspense>
    );
  }
);
