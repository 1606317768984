import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import Link from 'next/link';
import { GraphCMSRichContent } from '../../blog/components/GraphCMSRichContent';
import { FymButton } from '../../../components/FymButton';
import { useIsClient } from '../../../components/hooks/useIsClient';
import { BlogPostListItem, BlogPostsResponse } from '../../blog/types';
import { formattedBlogPublishedDate } from '../../blog/utils/formattedBlogPublishedDate';
import { getIMGIXOptimisedImage } from '../../blog/utils/getIMGIXOptimisedImage';

export interface Quote {
  message: string;
  name: string;
  stars: 1 | 2 | 3 | 4 | 5;
  store: 'google' | 'apple';
}

export const BlogPostElement: React.FC<{
  post: BlogPostListItem;
}> = React.memo(({ post }) => {
  const isClient = useIsClient();
  if (!isClient) {
    return null;
  }
  const promoImageURL = getIMGIXOptimisedImage(post.attributes.promoImage?.data, 'small');
  return (
    <Link
      prefetch={false}
      href={`/blog/${post.attributes.slug}`}
      className="index-blog-post-list-item d-flex flex-column"
    >
      {promoImageURL && <img src={promoImageURL} alt="" className="img-fluid" />}
      <div className="index-blog-post-list-content  px-2 px-md-5 pt-3 pb-2">
        <div className="index-blog-post-list-title">{post.attributes.title}</div>
        <div className="index-blog-post-list-tags pb-2">
          {post.attributes.blogPostTags.data.map((tag) => (
            <span key={tag.id}>{`#${tag.attributes.name} `}</span>
          ))}
        </div>
        {/* eslint-disable-next-line react/no-danger */}
        <GraphCMSRichContent content={post.attributes.introHtml} className="index-blog-post-list-intro" />
        <div className="index-blog-post-list-date pt-2"> {formattedBlogPublishedDate(post)}</div>
      </div>
    </Link>
  );
});

export const IndexBlogPosts: React.FC<{
  blogPosts: BlogPostsResponse;
}> = React.memo(({ blogPosts }) => {
  const intl = useIntl();
  if (blogPosts.data.length === 0) {
    return null;
  }
  return (
    <Container>
      <Row>
        <Col>
          <div className="mt-1 mt-lg-5 text-center">
            <div className="smaller-header mb-3 mx-auto">
              {intl.formatMessage({
                id: 'web.index.blog_posts.title',
                defaultMessage: 'Music Streaming News and Music Streaming Tips'
              })}
            </div>
            <p className="lead text-center">
              {intl.formatMessage({
                id: 'web.blog.page_description',
                defaultMessage:
                  'Transfer playlists easily and stay in the loop with the latest music streaming news and tips. Get tips on playlist management, industry updates, and how to make the most of your music subscription. FreeYourMusic - Your Music. Made Better.'
              })}
            </p>
          </div>
        </Col>
      </Row>
      <Row className="index-blog-posts mt-1 mt-lg-5">
        {blogPosts.data.map((blogPost) => (
          <BlogPostElement post={blogPost} key={blogPost.attributes.slug} />
        ))}
      </Row>
      <Row className="mb-5 pb-5">
        <Col className="text-center mt-5">
          <Link prefetch={false} href="/blog" passHref>
            <FymButton outline>
              <FormattedMessage id="web.index-blog.more" defaultMessage="More news & tips" />
            </FymButton>
          </Link>
        </Col>
      </Row>
    </Container>
  );
});
