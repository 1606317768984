import React, { Suspense, useMemo } from 'react';
import { Col, Container, Row } from 'reactstrap';
import Link from 'next/link';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import Image from 'next/image';
import { MigrationOption, MusicService } from '../../../components/musicServices/services/types';
import { permutatedMusicServices, supportedMusicServices } from '../../../components/musicServices/allMusicServices';

const { fromAlt } = defineMessages({
  fromAlt: {
    id: 'web.home.all-services.from-alt',
    defaultMessage: 'Transfer music from {from}'
  }
});

const MigrationOptionLinksElement: React.FC<{
  migrationOption: MigrationOption;
}> = React.memo(({ migrationOption }) => {
  return (
    <li>
      <Link prefetch={false} href={`/transfer-${migrationOption.from.id}-to-${migrationOption.to.id}`}>
        <FormattedMessage
          id="web.home.all-services.from-to"
          defaultMessage="Transfer from {from} to {to}"
          values={{
            from: migrationOption.from.shortName,
            to: <strong>{migrationOption.to.shortName}</strong>
          }}
        />
      </Link>
    </li>
  );
});
const MigrationOptionLinks: React.FC<{
  migrationOptions: MigrationOption[];
}> = React.memo(({ migrationOptions }) => {
  return (
    <ul className="list-unstyled migration-option-element-links">
      {migrationOptions.map((migrationOption) => {
        return (
          <MigrationOptionLinksElement
            migrationOption={migrationOption}
            key={`${migrationOption.from.id}-${migrationOption.to.id}`}
          />
        );
      })}
    </ul>
  );
});
const MigrationOptionElement: React.FC<{
  migrationOptions: MigrationOption[];
}> = React.memo(({ migrationOptions }) => {
  const intl = useIntl();
  return (
    <Col xs={6} sm={4} md={3} className="migration-option-element mx-auto">
      <Link
        href={`transfer-move-your-music-from-${migrationOptions[0].from.id}`}
        className="d-flex position-relative"
        style={{
          height: 45
        }}
      >
        <Suspense fallback={null}>
          <Image
            src={migrationOptions[0].from.logoColor.dark}
            alt={intl.formatMessage(fromAlt, { from: migrationOptions[0].from.name })}
            className="img-fluid"
            unoptimized
            fill
            sizes="100vw"
            style={{
              objectFit: 'contain',
              objectPosition: 'left'
            }}
          />
        </Suspense>
      </Link>
      <MigrationOptionLinks migrationOptions={migrationOptions} />
    </Col>
  );
});

export const MigrationOptionsLarge: React.FC = React.memo(() => {
  return (
    <Row>
      {permutatedMusicServices.map((migrationOptions, index) => {
        // eslint-disable-next-line react/no-array-index-key
        return <MigrationOptionElement key={index} migrationOptions={migrationOptions} />;
      })}
    </Row>
  );
});

export const MigrationOptionForMusicService: React.FC<{
  musicService: MusicService;
}> = React.memo(({ musicService }) => {
  const migrationOptions: MigrationOption[] = useMemo(() => {
    return supportedMusicServices.map((ms) => ({
      from: musicService,
      to: ms
    }));
  }, [musicService]);
  return (
    <Row>
      <MigrationOptionElement migrationOptions={migrationOptions} />;
    </Row>
  );
});

export const IndexAllMusicServices: React.FC = React.memo(() => {
  return (
    <Container className="all-music-streaming-services py-5" id="available-music-services">
      <Row>
        <Col>
          <h3 className="smaller-header text-center my-5">
            <FormattedMessage id="web.home.all-services.title" defaultMessage="All available services list" />
          </h3>
        </Col>
      </Row>
      <MigrationOptionsLarge />
    </Container>
  );
});
