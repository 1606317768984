import { FormattedMessage } from 'react-intl';
import React from 'react';
import { MovingOptionsCarousel } from '../../../components/musicServices/MovingOptionsCarousel';

export const IndexCarousel: React.FC = React.memo(() => {
  return (
    <>
      <div className="mt-1 mt-lg-5 text-center">
        <div className="smaller-header mb-3 mx-auto">
          <FormattedMessage id="web.home.top.title-carousel" defaultMessage="Personalize your transfers" />
        </div>
        <p className="lead text-center">
          <FormattedMessage
            id="web.home.top.subtitle-carousel"
            defaultMessage="Choose between various music platforms to create the perfect playlist transfer"
          />
        </p>
      </div>
      <MovingOptionsCarousel />
    </>
  );
});
